<template>
  <main class="payment_complete">
    <section class="section_header">
      <h1>상품 선택하기</h1>
      <div class="product_header_contents">
        <div>
          <span>1</span>
          <p>상품 선택하기</p>
        </div>
        <div>
          <span>2</span>
          <p></p>
        </div>
        <div>
          <span>3</span>
          <p></p>
        </div>
      </div>
    </section>
    <section class="pament_body">
      <article>
        <img src="@/assets/img/payment@3x.png" alt="결제 완료" />
        <h1>
          <span>{{ this.getUser.corporation.name }}</span> 담당자님!<br />
          스탬프 팝 페스티벌 상품<br class="br" /><span>무통장 입금 신청이 완료</span
          >되었습니다.
        </h1>
        <p>
          "우리은행 (주)넥스트이노베이션 1005-602-207500" 입금후 축제 자료를
          등록해주세요.
        </p>
      </article>
      <div class="btn_center">
        <base-button
          type="primary"
          @click="$router.push({ name: 'FestivalOrderReference' })"
          >축제 자료 등록하기</base-button
        >
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
export default {
  conponents: {
    BaseButton,
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
